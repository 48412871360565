<template>

  <div class="widget">

    <div class="widget-header">
      <div class="widget-header-label">
        <h3>{{ title }}</h3>
        <info-box :field="infoBox" />
      </div>
      <div class="widget-header-toolbar">
        <div class="actions">
          <!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
        </div>
      </div>
    </div>
    <div class="widget-body no-padding">
      <table class="vuetable" @copy="handleTableCopy">
        <thead>
          <tr>
            <th width="35%">{{ title }}</th>
            <th v-for="label in labels" :key="label">{{ label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="agg in data" :key="agg.key">
            <td v-if="title == 'Månad'">{{ capitalize(moment().locale('sv').month(agg.key).subtract(1, 'M').format('MMMM')) }}</td>
            <td v-else>{{ agg.key }}</td>

            <td class="fake-space" v-for="year in years(agg)" :key="year.key">
              <span class="formatted-number">{{ year.doc_count == 0 ? '' : year.doc_count.toLocaleString('sv-SE') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


</template>

<script>

import moment from 'moment';
import InfoBox from '@/components/InfoBox';

export default {
  data() {
    return {
      moment: moment,
      excludedYears: ['2017', '2018', '2019'], // Define the years to exclude
    };
  },
  props: {
    data: Array,
    title: String,
    yearsSelected: Array,
    infoBox: String,
  },
  components: {
    InfoBox,
  },
  computed: {
    filteredData() {
      return this.data.map((value) => {
        const newValue = { ...value }; // Create a shallow copy of the object

        // Filter reverted.year.buckets if it exists
        if (newValue.reverted?.year?.buckets) {
          newValue.reverted.year.buckets = newValue.reverted.year.buckets.filter(
            (bucket) => !this.excludedYears.includes(bucket.key)
          );
        }

        // Filter year.buckets if reverted is not present
        if (newValue.year?.buckets) {
          newValue.year.buckets = newValue.year.buckets.filter(
            (bucket) => !this.excludedYears.includes(bucket.key)
          );
        }

        return newValue;
      });
    },
    labels() {
      let labels = [];

      this.filteredData.forEach((value) => {
        const buckets = value.reverted?.year?.buckets || value.year?.buckets || [];

        buckets.forEach((bucket) => {
          if (!labels.includes(bucket.key)) {
            if (this.yearsSelected.length) {
              if (this.yearsSelected.includes(bucket.key)) {
                labels.push(bucket.key);
              }
            } else {
              labels.push(bucket.key);
            }
          }
        });
      });

      return labels;
    },
  },
  methods: {
    capitalize(word) {
      return word.trim().replace(/^\w/, (c) => c.toUpperCase());
    },
    handleTableCopy(event) {
      event.preventDefault();

      let copiedContent = '';
      const selection = window.getSelection();
      const selectedRows = selection.toString().split('\n');

      selectedRows.forEach((row) => {
        let rowData = '';
        const cells = row.split('\t');

        cells.forEach((cell) => {
          if (/\d+\s\d+/.test(cell)) {
            rowData += cell.replace(/\s+/g, '') + '\t';
          } else {
            rowData += cell + '\t';
          }
        });

        copiedContent += rowData.trim() + '\n';
      });

      event.clipboardData.setData('text/plain', copiedContent.trim());
    },
    years(agg) {
      const excludedYears = this.excludedYears;

      if (Object.prototype.hasOwnProperty.call(agg, 'reverted')) {
        const buckets = agg.reverted.year.buckets.filter(
          (y) => !excludedYears.includes(y.key)
        );

        if (this.yearsSelected.length) {
          return buckets.filter((y) => this.yearsSelected.includes(y.key));
        }

        return buckets;
      } else {
        const buckets = agg.year.buckets.filter(
          (y) => !excludedYears.includes(y.key)
        );

        if (this.yearsSelected.length) {
          return buckets.filter((y) => this.yearsSelected.includes(y.key));
        }

        return buckets;
      }
    },
  },
};
</script>